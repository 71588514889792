import React from "react"
import AddLisitngLayout from "../components/Layout/AddListingLayout"
import Seo from "../components/seo"
import { useAuthContext } from "../hooks/useAuthContext"
import EditLisitngProcess from "../components/EditListingProcess"
import MustSignIn from "../components/MustSignIn"
import { graphql } from "gatsby"
const EditListing = ({ location }) => {
  const { user, authIsReady } = useAuthContext()
  const listing = location.state ? location.state.listing : null
  return (
    <>
      {authIsReady && user && (
        <AddLisitngLayout>
          <Seo title="Edit listing" />
          {listing && <EditLisitngProcess listing={listing} />}
          {!listing && (
            <>
              <div className="container py-5">
                <h1>You cannot access this page</h1>
              </div>
            </>
          )}
        </AddLisitngLayout>
      )}
      {authIsReady && !user && (
        <>
          <MustSignIn />
        </>
      )}
    </>
  )
}
export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
  }
`
export default EditListing
