import React, { useState, useEffect } from "react"
import HotelsOrResorts from "../AddListingProcess/HotelsOrResorts"
import VacantLand from "../AddListingProcess/vacantland"
import {
  UpperInfo,
  NextPrev,
} from "../AddListingProcess/AddListingProcess.styles"
import Information from "../AddListingProcess/information"
import Location from "../AddListingProcess/location"
import Details from "../AddListingProcess/details"
import Financial from "../AddListingProcess/financial"
import Amenities from "../AddListingProcess/amenities"
import { EditListingContext } from "./context"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { doc, updateDoc } from "firebase/firestore"
import { db, storage } from "../../firebase/config"
import Loader from "../Loader"
import ListingComplete from "../AddListingProcess/listingComplete"

import { useCollection } from "../../hooks/useCollection"

const EditLisitngProcess = ({ listing, arabic }) => {
  const data = listing.data

  /* ------- setting the fields -----------*/

  // constants use States
  const [constants, setConstants] = useState([])
  const [propertyTypeConstants, setPropertyTypeConstants] = useState([])
  const [countryConstants, setCountryConstants] = useState([])
  const [saleOrRentConstant, setSaleOrRentConstant] = useState([])
  const [paymentMethodContants, setPaymentMethodConstants] = useState([])
  // hotesl and resorts
  const [numOfFloors, setNumOfFloors] = useState(data.numOfFloors)
  const [numOfReceptionStations, setNumOfReceptionStations] = useState(
    data.numOfReceptionStations
  )
  const [numOfAdminstrationOffices, setNumOfAdminstrationOffices] = useState(
    data.numOfAdminstrationOffices
  )
  const [numOfKinglslySuits, setNumOfKinglslySuits] = useState(
    data.numOfKinglslySuits
  )
  const [
    areaOfKingslySuitInSMetersOrSFeet,
    setAreaOfKingslySuitInSMetersOrSFeet,
  ] = useState(data.areaOfKingslySuitInSMetersOrSFeet)
  const [numOfRegularSuits, setNumOfRegularSuits] = useState(
    data.numOfRegularSuits
  )
  const [
    areaOfRegularSuitsInSMetersOrSFeet,
    setAreaOfRegularSuitsInSMetersOrSFeet,
  ] = useState(data.areaOfRegularSuitsInSMetersOrSFeet)
  const [numOfSingleBedroomsWithBath, setNumOfSingleBedroomsWithBath] =
    useState(data.numOfSingleBedroomsWithBath)
  const [
    areaOfSingleBedroomsWithBathInSMetersOrSFeet,
    setAreaOfSingleBedroomsWithBathInSMetersOrSFeet,
  ] = useState(data.areaOfSingleBedroomsWithBathInSMetersOrSFeet)
  const [numOfDoubleBedroomsWithBath, setNumOfDoubleBedroomsWithBath] =
    useState(data.numOfDoubleBedroomsWithBath)
  const [
    areaOfDoubleBedroomsWithBathInSMetersOrSFeet,
    setAreaOfDoubleBedroomsWithBathInSMetersOrSFeet,
  ] = useState(data.areaOfDoubleBedroomsWithBathInSMetersOrSFeet)
  const [numOfChaletsOrSeparateHouses, setNumOfChaletsOrSeparateHouses] =
    useState(data.numOfChaletsOrSeparateHouses)
  const [
    areaOfChaletsOrSeparateousesInSMetersOrSFeet,
    setAreaOfChaletsOrSeparateousesInSMetersOrSFeet,
  ] = useState(data.areaOfChaletsOrSeparateousesInSMetersOrSFeet)
  const [numOfCarParkingLots, setNumOfCarParkingLots] = useState(
    data.numOfCarParkingLots
  )
  const [numOfLifts, setNumOfLifts] = useState(data.numOfLifts)
  const [numOfRestaurantsAndCoffeeShops, setNumOfRestaurantsAndCoffeeShops] =
    useState(data.numOfRestaurantsAndCoffeeShops)
  const [secutriyCamerasAndGaurds, setSecutriyCamerasAndGaurds] = useState(
    data.secutriyCamerasAndGaurds
  )
  const [availabilityOfWater, setAvailabilityOfWater] = useState(
    data.availabilityOfWater
  )
  const [availabiltyOfInternetService, setAvailabiltyOfInternetService] =
    useState(data.availabiltyOfInternetService)
  const [availabilityOfGardens, setAvailabilityOfGardens] = useState(
    data.availabilityOfGardens
  )
  const [
    numOfInsideSwimmingPoolsAndShowers,
    setNumOfInsideSwimmingPoolsAndShowers,
  ] = useState(data.numOfInsideSwimmingPoolsAndShowers)
  const [
    numOfOpenAirSwimmingPoolsAndShowers,
    setNumOfOpenAirSwimmingPoolsAndShowers,
  ] = useState(data.numOfOpenAirSwimmingPoolsAndShowers)
  const [numOfElectricGenerators, setNumOfElectricGenerators] = useState(
    data.numOfElectricGenerators
  )
  const [numOfConferenceRooms, setNumOfConferenceRooms] = useState(
    data.numOfConferenceRooms
  )
  const [numOfLounges, setNumOfLounges] = useState(data.numOfLounges)
  const [numOfWeddingHalls, setNumOfWeddingHalls] = useState(
    data.numOfWeddingHalls
  )
  const [numOfMeetingHalls, setNumOfMeetingHalls] = useState(
    data.numOfMeetingHalls
  )
  const [numOfConferenceHalls, setNumOfConferenceHalls] = useState(
    data.numOfConferenceHalls
  )
  const [numOfStaffRooms, setNumOfStaffRooms] = useState(data.numOfStaffRooms)

  // vacant land
  const [landclassification, setLandclassification] = useState(
    data.landclassification
  )
  const [isitneara, setIsitneara] = useState(data.isitneara)
  const [natureandlocation, setNatureandlocation] = useState(
    data.natureandlocation
  )
  const [doyouhaveaccessto, setDoyouhaveaccessto] = useState(
    data.doyouhaveaccessto
  )
  const [variouslegalpossibilities, setVariouslegalpossibilities] = useState(
    data.variouslegalpossibilities
  )

  // Information useState
  const [listingTitle, setListingTitle] = useState(data.title)
  const [propertyTypeSubcategory, setPropertyTypeSubcategory] = useState(
    data.type_subcategory
  )
  const [placedBy, setPlacedBy] = useState(data.placedby)
  const [propertyType, setPropertyType] = useState(data.type)
  const [description, setDescription] = useState(data.description)
  const [featuredImage, setFeaturedImage] = useState(data.featured_image.url)
  const [galleryImages, setGalleryImages] = useState(
    data.gallery.map(image => {
      return image.url
    })
  )
  const [featuredImagePath, setFeaturedImagePath] = useState(
    data.featured_image.reference
  )
  const [galleryImagesPaths, setGalleryImagesPaths] = useState(
    data.gallery.map(image => {
      return image.reference
    })
  )
  // missing from the create listing and added later in edit listing
  const [selectedCurrency, setSelectedCurrency] = useState(
    data.selectedCurrency
  )
  const [city, setCity] = useState(data.city)
  const [village, setVillage] = useState(data.village)
  const [listingplacedBy, setListingplacedBy] = useState(data.listingplacedBy)
  const [Feetorm2, setFeetorm2] = useState(data.Feetorm2)
  const [createNewListingState, setCreateNewListingState] = useState(false)
  const [governate, setGovernate] = useState(data.governate)
  const [upvideourl, setUpvideourl] = useState(data.upvideourl)

  // location useState
  const [country, setCountry] = useState(data.country)
  const [district, setDistrict] = useState(data.district ? data.district : "")
  const [caza, setCaza] = useState(data.caza)
  const [town, setTown] = useState(data.town)
  const [street, setStreet] = useState(data.street_name)
  const [zipCode, setZipCode] = useState(data.zip_code ? data.zip_code : "")
  const [address, setAddress] = useState(data.address)
  const [building, setBuilding] = useState(data.building)
  const [longitude, setLongitude] = useState(data.longitude)
  const [latitude, setLatitude] = useState(data.latitude)

  // details useState
  const [numBedrooms, setNumBedrooms] = useState(data.num_bedrooms)
  const [numBathrooms, setNumBathrooms] = useState(data.num_bathrooms)
  const [numSalons, setNumSalons] = useState(data.num_salons)
  const [numDiningRooms, setNumDiningRooms] = useState(data.num_dining_rooms)
  const [numLivingRoom, setNumLivingRooms] = useState(data.num_living_room)
  const [numBalcony, setNumBalcony] = useState(data.num_balcony)
  const [numFloors, setNumFloors] = useState(data.num_floors)
  const [apartmentsPerFloor, setApartmentsPerFloor] = useState(
    data.apartments_per_floor
  )
  const [area, setArea] = useState(data.area)
  const [legalStatus, setLegalStatus] = useState(data.legal_status)
  const [paymentMethod, setPaymentMethod] = useState(data.payment_method)
  const [otherPaymentMethod, setOtherPaymentMethod] = useState(
    data.other_payment_method
  )

  // financials useState
  const [afterRepairValue, setAfterRepairValue] = useState(
    data.after_repair_value
  )
  const [saleOrRent, setSaleOrRent] = useState(data.rent_or_sale)
  const [rentPrice, setRentPrice] = useState(data.rent_price)
  const [salePrice, setSalePrice] = useState(data.sale_price)
  const [currentRent, setCurrentRent] = useState(data.current_rent)
  const [builtTax, setBuiltTax] = useState(data.built_tax)
  const [insurance, setInsurance] = useState(data.insurance)
  const [propertyManagement, setPropertyManagement] = useState(
    data.property_management
  )
  const [grossCashflow, setGrossCashFlow] = useState(data.gross_cashflow)
  const [capRate, setCapRate] = useState(data.cap_rate)

  // amenities
  const [checkedAmenities, setCheckedAmenities] = useState(
    data.checkedAmenities
  )
  const [addedAmenities, setAddedAmenities] = useState(data.addedAmenities)

  // for the process
  const [listingProcess, updateListingProcess] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  //handling the upload of thumbnail to firebase
  const uploadThumbnailProcess = () => {
    return new Promise(resolve => {
      if (featuredImage.name) {
        const reference = ref(storage, featuredImagePath)
        uploadBytes(reference, featuredImage).then(() => {
          getDownloadURL(reference).then(url => {
            resolve({ url: url, reference: featuredImagePath })
          })
        })
      } else {
        resolve({ url: featuredImage, reference: featuredImagePath })
      }
    })
  }

  // handling the upload of gallery to firebase
  const uploadGalleryProcess = async () => {
    return new Promise(resolve => {
      const gallery = [...galleryImages]
      let processed = 0
      const arrayToBeUploaded = []
      if (gallery.length === 0) {
        resolve([])
      }
      gallery.forEach((image, index) => {
        if (image.name) {
          const reference = ref(storage, galleryImagesPaths[index])
          uploadBytes(reference, image).then(() => {
            getDownloadURL(reference).then(url => {
              arrayToBeUploaded.push({
                url: url,
                reference: galleryImagesPaths[index],
              })
              processed++
              if (processed === gallery.length) {
                resolve(arrayToBeUploaded)
              }
            })
          })
        } else {
          arrayToBeUploaded.push({
            url: galleryImages[index],
            reference: galleryImagesPaths[index],
          })
          processed++
          if (processed === gallery.length) {
            resolve(arrayToBeUploaded)
          }
        }
      })
    })
  }

  const saveListing = async isDraft => {
    setIsLoading(true)
    const listingRef = doc(db, "property", listing.id)
    let thumbnailUpload, galleryUpload

    // upload thumbnail first
    uploadThumbnailProcess().then(value => {
      thumbnailUpload = value

      // uploading thumbnail finished upload gallery next
      uploadGalleryProcess().then(value => {
        galleryUpload = value

        updateDoc(listingRef, {
          title: listingTitle,
          placedby: placedBy,
          upvideourl: upvideourl,
          type: propertyType,
          type_subcategory: propertyTypeSubcategory,
          description: description,
          isDraft: isDraft,
          featured_image: thumbnailUpload,
          gallery: galleryUpload,
          country: country,
          district: district,
          caza: caza,
          town: town,
          street_name: street,
          zip_code: zipCode,
          address: address,
          building: building ? building : "",
          longitude: longitude,
          latitude: latitude,
          num_bedrooms: numBedrooms,
          num_bathrooms: numBathrooms,
          num_balcony: numBalcony,
          num_salons: numSalons,
          num_living_room: numLivingRoom,
          num_dining_rooms: numDiningRooms,
          num_floors: numFloors,
          rent_price: rentPrice,
          apartments_per_floor: apartmentsPerFloor,
          area: area,
          legal_status: legalStatus,
          payment_method: paymentMethod,
          other_payment_method: otherPaymentMethod,
          after_repair_value: afterRepairValue,
          rent_or_sale: saleOrRent,
          sale_price: salePrice,
          current_rent: currentRent,
          built_tax: builtTax,
          property_management: propertyManagement,
          cap_rate: capRate,
          insurance: insurance,
          gross_cashflow: grossCashflow,
          checkedAmenities: checkedAmenities,
          addedAmenities: addedAmenities,
          selectedCurrency: selectedCurrency,
          PlacedBy: placedBy,
          /* added for hotels and resorts fields */
          numOfFloors: numOfFloors,
          numOfReceptionStations: numOfReceptionStations,
          numOfAdminstrationOffices: numOfAdminstrationOffices,
          numOfKinglslySuits: numOfKinglslySuits,
          areaOfKingslySuitInSMetersOrSFeet: areaOfKingslySuitInSMetersOrSFeet,
          numOfRegularSuits: numOfRegularSuits,
          areaOfRegularSuitsInSMetersOrSFeet:
            areaOfRegularSuitsInSMetersOrSFeet,
          numOfSingleBedroomsWithBath: numOfSingleBedroomsWithBath,
          areaOfSingleBedroomsWithBathInSMetersOrSFeet:
            areaOfSingleBedroomsWithBathInSMetersOrSFeet,
          numOfDoubleBedroomsWithBath: numOfDoubleBedroomsWithBath,
          areaOfDoubleBedroomsWithBathInSMetersOrSFeet:
            areaOfDoubleBedroomsWithBathInSMetersOrSFeet,
          numOfChaletsOrSeparateHouses: numOfChaletsOrSeparateHouses,
          areaOfChaletsOrSeparateousesInSMetersOrSFeet:
            areaOfChaletsOrSeparateousesInSMetersOrSFeet,
          numOfCarParkingLots: numOfCarParkingLots,
          numOfLifts: numOfLifts,
          numOfRestaurantsAndCoffeeShops: numOfRestaurantsAndCoffeeShops,
          secutriyCamerasAndGaurds: secutriyCamerasAndGaurds,
          availabilityOfWater: availabilityOfWater,
          availabilityOfGardens: availabilityOfGardens,
          numOfInsideSwimmingPoolsAndShowers:
            numOfInsideSwimmingPoolsAndShowers,
          numOfOpenAirSwimmingPoolsAndShowers:
            numOfOpenAirSwimmingPoolsAndShowers,
          numOfElectricGenerators: numOfElectricGenerators,
          numOfConferenceRooms: numOfConferenceRooms,
          numOfLounges: numOfLounges,
          numOfWeddingHalls: numOfWeddingHalls,
          numOfMeetingHalls: numOfMeetingHalls,
          numOfConferenceHalls: numOfConferenceHalls,
          numOfStaffRooms: numOfStaffRooms,
          /* added for vacant land values */
          landclassification: landclassification,
          isitneara: isitneara,
          natureandlocation: natureandlocation,
          doyouhaveaccessto: doyouhaveaccessto,
          variouslegalpossibilities: variouslegalpossibilities,
        }).then(() => {
          setIsLoading(false)
          setListingTitle("")
          setListingplacedBy("")
          setPropertyTypeSubcategory({})
          setPropertyType({})
          setDescription("")
          setFeaturedImage(null)
          setGalleryImages([])
          setFeaturedImagePath(null)
          setGalleryImagesPaths([])
          setCountry({})
          setDistrict({})
          setCaza({})
          setTown("")
          setStreet("")
          setZipCode("")
          setAddress("")
          setBuilding("")
          setLongitude("")
          setLatitude("")
          setNumBedrooms("0")
          setNumBathrooms("0")
          setNumSalons("0")
          setNumDiningRooms("0")
          setNumLivingRooms("0")
          setNumBalcony("0")
          setNumFloors("0")
          setApartmentsPerFloor("0")
          setArea("")
          setFeetorm2("")
          setLegalStatus("")
          setPaymentMethod({})
          setOtherPaymentMethod("")
          setAfterRepairValue("")
          setSaleOrRent({})
          setRentPrice("")
          setSalePrice("")
          setCurrentRent("")
          setBuiltTax("")
          setInsurance("")
          setPropertyManagement("")
          setGrossCashFlow("")
          setCapRate("")
          setAddedAmenities([])
          setCheckedAmenities([])
          setSelectedCurrency("")
          setPlacedBy("")
          setLandclassification("")
          setIsitneara("")
          setNatureandlocation("")
          setDoyouhaveaccessto("")
          setVariouslegalpossibilities("")
          if (isDraft) {
            updateListingProcess(6)
          } else {
            updateListingProcess(7)
          }
        })
      })
    })
  }

  // getting all the property constants
  useCollection("property_constants").then(values => {
    if (values) {
      setConstants(values)
    }
  })

  useCollection("countries").then(values => {
    if (values) {
      setCountryConstants(values)
    }
  })

  // get the property type constants
  useCollection("property_type").then(values => {
    if (values) {
      setPropertyTypeConstants(values)
    }
  })

  useEffect(() => {
    if (constants) {
      constants.forEach(constant => {
        switch (constant.id) {
          case "rent_or_sale":
            setSaleOrRentConstant(constant.data.fields)
            break

          case "payment_method":
            setPaymentMethodConstants(constant.data.fields)
            break

          default:
            break
        }
      })
    }
  }, [constants])

  return (
    <EditListingContext.Provider
      value={{
        setListingTitle,
        setListingplacedBy,
        setPropertyType,
        setPropertyTypeSubcategory,
        setDescription,
        setFeaturedImage,
        setFeaturedImagePath,
        setGalleryImages,
        setGalleryImagesPaths,
        setCountry,
        setDistrict,
        setCaza,
        setTown,
        setCity,
        setVillage,
        setStreet,
        setZipCode,
        setAddress,
        setBuilding,
        setLongitude,
        setLatitude,
        setNumBedrooms,
        setNumSalons,
        setNumDiningRooms,
        setNumLivingRooms,
        setNumBalcony,
        setNumFloors,
        setApartmentsPerFloor,
        setLegalStatus,
        setPaymentMethod,
        setOtherPaymentMethod,
        setRentPrice,
        setArea,
        setFeetorm2,
        setNumBathrooms,
        setSalePrice,
        setSaleOrRent,
        setAfterRepairValue,
        setCurrentRent,
        setBuiltTax,
        setGrossCashFlow,
        setPropertyManagement,
        setCapRate,
        setInsurance,
        setAddedAmenities,
        setCheckedAmenities,
        setCreateNewListingState,
        setIsLoading,
        setSelectedCurrency,
        setPlacedBy,
        /* new fields for governate and embeded video */
        setGovernate,
        setUpvideourl,
        /* new fields for hotels or resorts */
        setNumOfFloors,
        setNumOfReceptionStations,
        setNumOfAdminstrationOffices,
        setNumOfKinglslySuits,
        setAreaOfKingslySuitInSMetersOrSFeet,
        setNumOfRegularSuits,
        setAreaOfRegularSuitsInSMetersOrSFeet,
        setNumOfSingleBedroomsWithBath,
        setAreaOfSingleBedroomsWithBathInSMetersOrSFeet,
        setNumOfDoubleBedroomsWithBath,
        setAreaOfDoubleBedroomsWithBathInSMetersOrSFeet,
        setNumOfChaletsOrSeparateHouses,
        setAreaOfChaletsOrSeparateousesInSMetersOrSFeet,
        setNumOfCarParkingLots,
        setNumOfLifts,
        setNumOfRestaurantsAndCoffeeShops,
        setSecutriyCamerasAndGaurds,
        setAvailabilityOfWater,
        setAvailabiltyOfInternetService,
        setAvailabilityOfGardens,
        setNumOfInsideSwimmingPoolsAndShowers,
        setNumOfOpenAirSwimmingPoolsAndShowers,
        setNumOfElectricGenerators,
        setNumOfConferenceRooms,
        setNumOfLounges,
        setNumOfWeddingHalls,
        setNumOfMeetingHalls,
        setNumOfConferenceHalls,
        setNumOfStaffRooms,
        /* new fields for vacant land */
        setLandclassification,
        setIsitneara,
        setNatureandlocation,
        setDoyouhaveaccessto,
        setVariouslegalpossibilities,
      }}
    >
      <div className="container">
        <UpperInfo>
          <div className="text">
            <h1>{arabic === false  ? "Edit a Lisitng" : "تحرير قائمة"}</h1>
            <p>
              {arabic === false 
                ? "Please enter your listing informationn"
                : "الرجاء إدخال معلومات القائمة الخاصة بك"}
            </p>
          </div>
          <div className="save">
            <button className="save" onClick={() => saveListing(true)}>
              {arabic === false  ? "SAVE & EXIT" : "احفظ المخرج"}
            </button>
          </div>
        </UpperInfo>
        {listingProcess === 1 && propertyTypeConstants && (
          <>
            <Information
              listingTitle={listingTitle}
              listingplacedBy={listingplacedBy}
              upvideourl={upvideourl}
              propertyType={propertyType}
              propertyTypeSubcategory={propertyTypeSubcategory}
              description={description}
              featuredImage={featuredImage}
              featuredImagePath={featuredImagePath}
              galleryImages={galleryImages}
              galleryImagesPaths={galleryImagesPaths}
              property_type_constants={propertyTypeConstants}
              placedBy={placedBy}
              isEdit={true}
              arabic={arabic}
            />
            <NextPrev>
              {(listingTitle &&
                Object.keys(propertyType).length !== 0 &&
                Object.keys(propertyTypeSubcategory).length !== 0 &&
                description &&
                featuredImage && (
                  <button
                    className="next"
                    onClick={() => updateListingProcess(listingProcess + 1)}
                  >
                    {arabic === false  ? "Next" : "التالي"}
                  </button>
                )) || (
                <button className="next disabled" disabled>
                  {arabic === false  ? "Next" : "التالي"}
                </button>
              )}
            </NextPrev>
          </>
        )}
        {listingProcess === 2 && countryConstants && (
          <>
            <Location
              country={country}
              district={district}
              governate={governate}
              town={town}
              city={city}
              village={village}
              street={street}
              building={building}
              longitude={longitude}
              latitude={latitude}
              country_constants={countryConstants}
              isEdit={true}
              arabic={arabic}
            />
            <NextPrev>
              {(Object.keys(country).length !== 0 && longitude && (
                <>
                  <button
                    className="prev"
                    onClick={() => updateListingProcess(listingProcess - 1)}
                  >
                    {arabic === false  ? "Back" : "خلف"}
                  </button>
                  <button
                    className="next"
                    onClick={() => updateListingProcess(listingProcess + 1)}
                  >
                    {arabic === false  ? "Next" : "التالي"}
                  </button>
                </>
              )) || (
                <>
                  <button
                    className="prev"
                    onClick={() => updateListingProcess(listingProcess - 1)}
                  >
                    {arabic === false  ? "Back" : "خلف"}
                  </button>
                  <button className="next disabled" disabled>
                    {arabic === false  ? "Next" : "التالي"}
                  </button>
                </>
              )}
            </NextPrev>
          </>
        )}
        {listingProcess === 3 && (
          <>
            {propertyType.value === "ld2eqvrOSB5gLr1jEu3w" && (
              <VacantLand
                landclassification={landclassification}
                isitneara={isitneara}
                natureandlocation={natureandlocation}
                doyouhaveaccessto={doyouhaveaccessto}
                variouslegalpossibilities={variouslegalpossibilities}
                isEdit={true}
                arabic={arabic}
              />
            )}
            {propertyTypeSubcategory.value === "hotel" && (
              <HotelsOrResorts
                numOfFloors={numOfFloors}
                numOfReceptionStations={numOfReceptionStations}
                numOfAdminstrationOffices={numOfAdminstrationOffices}
                numOfKinglslySuits={numOfKinglslySuits}
                areaOfKingslySuitInSMetersOrSFeet={
                  areaOfKingslySuitInSMetersOrSFeet
                }
                numOfRegularSuits={numOfRegularSuits}
                areaOfRegularSuitsInSMetersOrSFeet={
                  areaOfRegularSuitsInSMetersOrSFeet
                }
                numOfSingleBedroomsWithBath={numOfSingleBedroomsWithBath}
                areaOfSingleBedroomsWithBathInSMetersOrSFeet={
                  areaOfSingleBedroomsWithBathInSMetersOrSFeet
                }
                numOfDoubleBedroomsWithBath={numOfDoubleBedroomsWithBath}
                areaOfDoubleBedroomsWithBathInSMetersOrSFeet={
                  areaOfDoubleBedroomsWithBathInSMetersOrSFeet
                }
                numOfChaletsOrSeparateHouses={numOfChaletsOrSeparateHouses}
                areaOfChaletsOrSeparateousesInSMetersOrSFeet={
                  areaOfChaletsOrSeparateousesInSMetersOrSFeet
                }
                numOfCarParkingLots={numOfCarParkingLots}
                numOfLifts={numOfLifts}
                numOfRestaurantsAndCoffeeShops={numOfRestaurantsAndCoffeeShops}
                secutriyCamerasAndGaurds={secutriyCamerasAndGaurds}
                availabilityOfWater={availabilityOfWater}
                availabiltyOfInternetService={availabiltyOfInternetService}
                availabilityOfGardens={availabilityOfGardens}
                numOfInsideSwimmingPoolsAndShowers={
                  numOfInsideSwimmingPoolsAndShowers
                }
                numOfOpenAirSwimmingPoolsAndShowers={
                  numOfOpenAirSwimmingPoolsAndShowers
                }
                numOfElectricGenerators={numOfElectricGenerators}
                numOfConferenceRooms={numOfConferenceRooms}
                numOfLounges={numOfLounges}
                numOfWeddingHalls={numOfWeddingHalls}
                numOfMeetingHalls={numOfMeetingHalls}
                numOfConferenceHalls={numOfConferenceHalls}
                numOfStaffRooms={numOfStaffRooms}
                isEdit={true}
                arabic={arabic}
              />
            )}
            <Details
              numBedrooms={numBedrooms}
              numBathrooms={numBathrooms}
              numSalons={numSalons}
              numDiningRooms={numDiningRooms}
              numLivingRoom={numLivingRoom}
              numBalcony={numBalcony}
              numFloors={numFloors}
              apartmentsPerFloor={apartmentsPerFloor}
              legalStatus={legalStatus}
              area={area}
              Feetorm2={Feetorm2}
              paymentMethod={paymentMethod}
              otherPaymentMethod={otherPaymentMethod}
              paymentMethodContants={paymentMethodContants}
              isEdit={true}
              arabic={arabic}
            />
            <NextPrev>
              {(
                <>
                  <button
                    className="prev"
                    onClick={() => updateListingProcess(listingProcess - 1)}
                  >
                    {arabic === false  ? "Back" : "خلف"}
                  </button>
                  <button
                    className="next"
                    onClick={() => updateListingProcess(listingProcess + 1)}
                  >
                    {arabic === false  ? "Next" : "التالي"}
                  </button>
                </>
              ) || (
                <>
                  <button
                    className="prev"
                    onClick={() => updateListingProcess(listingProcess - 1)}
                  >
                    {arabic === false  ? "Back" : "خلف"}
                  </button>
                  <button className="next disabled" disabled>
                    {arabic === false  ? "Next" : "التالي"}
                  </button>
                </>
              )}
            </NextPrev>
          </>
        )}
        {listingProcess === 4 && (
          <>
            <Financial
              afterRepairValue={afterRepairValue}
              saleOrRent={saleOrRent}
              rentPrice={rentPrice}
              salePrice={salePrice}
              currentRent={currentRent}
              builtTax={builtTax}
              propertyManagement={propertyManagement}
              grossCashflow={grossCashflow}
              capRate={capRate}
              insurance={insurance}
              isEdit={true}
              sale_or_rent_constants={saleOrRentConstant}
              selectedCurrency={selectedCurrency}
              arabic={arabic}
            />
            <NextPrev>
              {(salePrice || rentPrice) && (
                <>
                  <button
                    className="prev"
                    onClick={() => updateListingProcess(listingProcess - 1)}
                  >
                    {arabic === false  ? "Back" : "خلف"}
                  </button>
                  <button
                    className="next"
                    onClick={() => updateListingProcess(listingProcess + 1)}
                  >
                    {arabic === false  ? "Next" : "التالي"}
                  </button>
                </>
              )}
              {!(salePrice || rentPrice) && (
                <>
                  <button
                    className="prev"
                    onClick={() => updateListingProcess(listingProcess - 1)}
                  >
                    {arabic === false  ? "Back" : "خلف"}
                  </button>
                  <button className="next disabled" disabled>
                    {arabic === false  ? "Next" : "التالي"}
                  </button>
                </>
              )}
            </NextPrev>
          </>
        )}
        {listingProcess === 5 && (
          <>
            <Amenities
              addedAmenities={addedAmenities}
              checkedAmenities={checkedAmenities}
              arabic={arabic}
              isEdit={true}
            />
            <NextPrev>
              <button
                className="prev"
                onClick={() => updateListingProcess(listingProcess - 1)}
              >
                {arabic === false  ? "Back" : "خلف"}
              </button>
              <button className="next" onClick={() => saveListing(false)}>
                {arabic === false  ? "Finish" : "إنهاء"}
              </button>
            </NextPrev>
          </>
        )}
        {listingProcess === 6 && (
          <ListingComplete isDraft={true} arabic={arabic} />
        )}
        {listingProcess === 7 && (
          <ListingComplete isDraft={false} arabic={arabic} />
        )}
      </div>
      {isLoading && <Loader />}
    </EditListingContext.Provider>
  )
}

export default EditLisitngProcess
